import Link from 'next/link';
import { FacilityBotLogo, FacilityBotNewyear2025Logo } from '../Svg';

const NEW_YEAR_2025_START_DATE = new Date('2024-12-25');
const NEW_YEAR_2025_END_DATE = new Date('2025-02-01');

function LogoByDate() {
	let today = new Date();

	if (today > NEW_YEAR_2025_START_DATE && today < NEW_YEAR_2025_END_DATE) 
		return <FacilityBotNewyear2025Logo />;
	else 
		return <FacilityBotLogo />;
}

export default function HeaderLogo() {
	return (
		<div className="header__logo">
			<Link href="/">
				<LogoByDate />
			</Link>
		</div>
	);
}
