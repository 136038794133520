const PLANS = {
	Basic: {
		name: 'Basic',
		maximum_accounts: 10,
		currency: {
			USD: {
				annual: 450,
				monthly: 540,
			},
			SGD: {
				annual: 600,
				monthly: 720,
			},
			INR: {
				annual: 36000,
				monthly: 44000,
			},
		},
	},
	Professional: {
		name: 'Professional',
		maximum_accounts: 25,
		currency: {
			USD: {
				annual: 925,
				monthly: 1110,
			},
			SGD: {
				annual: 1250,
				monthly: 1500,
			},
			INR: {
				annual: 75000,
				monthly: 90000,
			},
		},
	},
	Plus: {
		name: 'Plus',
		maximum_accounts: 50,
		currency: {
			USD: {
				annual: 1850,
				monthly: 2220,
			},
			SGD: {
				annual: 2500,
				monthly: 3000,
			},
			INR: {
				annual: 150000,
				monthly: 180000,
			},
		},
		additional_account_price: {
			USD: {
				annual: 37,
				monthly: 45,
			},
			SGD: {
				annual: 50,
				monthly: 60,
			},
			INR: {
				annual: 3000,
				monthly: 3600,
			},
		},
	},
};

const CUSTOM_CELL_VALUE = {
	// Pricing table cells with custom value render logic
	dynamicSensorsLimit: 'limit_sensors',
	enhancedOnboardingLink: 'custom_onboarding',
	dynamicStorage: 'custom_storage',
};

const ALL_PLANS = Object.entries(PLANS).map(([key, planObj]) => planObj); // array of all defined plans above

function getAppliedPlans(appliedPlans) {
	// Receives an array of applied plans
	let values = [];
	for (let key in PLANS) {
		values.push(appliedPlans.includes(PLANS[key]) ? true : false);
	}

	return values;
}

function getPlanValues(planValues) {
	// Receive an object of any size, object's keys = Defined plan's name
	let values = [];
	for (let definedPlan in PLANS) {
		if (definedPlan in planValues) values.push(planValues[definedPlan]);
		else values.push(false);
	}

	return values;
}

const PLAN_BENEFITS = [
	{
		feature_group_name: 'Accounts',
		features: [
			{
				name: 'Unlimited Requestors',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Maximum number of Admin, Manager and Responder Accounts',
				value: getPlanValues({
					Basic: 10,
					Professional: 25,
					Plus: 50,
				}),
			},
			{
				name: 'Custom Manager Accounts',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Mobile',
		features: [
			{
				name: 'Requestor Mobile App',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Responder Mobile App',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Push Notifications',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Requests',
		features: [
			{
				name: 'Unlimited Requests ("Work Orders")',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Fault Reporting',
				value: getAppliedPlans(ALL_PLANS),
				link: 'features/fault-reporting',
			},
			{
				name: 'Custom Service Requests',
				value: getAppliedPlans(ALL_PLANS),
				link: 'features/custom-service-requests',
			},
			{
				name: 'Export Requests to CSV',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Generate Request PDF',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Checklists',
		link: 'features/preventive-maintenance',
		features: [
			{
				name: 'Unlimited Checklists',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Unlimited Rows in Checklists',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Checklist Builder',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Checklist Templates',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Checklist Scheduling',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Checklist Calendar',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Send Checklists via Email',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Completion of Checklists via Mobile App',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Assets',
		link: 'features/asset-management',
		features: [
			{
				name: 'Unlimited Assets',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Import Assets',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Custom Asset Fields',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Asset Hierachy view',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Asset Request History',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Asset Checklist History',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Asset Parts History',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Asset Metering History',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'QR Code Web Forms',
		link: 'features/qr_code_fault_reporting',
		features: [
			{
				name: 'Unlimited Web Forms',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Location QR Code Web Forms',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Asset QR Code Web Forms',
				value: getAppliedPlans(ALL_PLANS),
				link: 'features/qr-code-scanning',
			},
			{
				name: 'Toilet Feedback Form Webview',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Meters',
		link: 'features/meters',
		features: [
			{
				name: 'Unlimited Meters',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Scheduled Meter Readings via Checklists',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'IoT Meter Readings',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Parts',
		link: 'features/parts',
		features: [
			{
				name: 'Unlimited Parts',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Link Parts to Assets',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Permit to Work',
		link: 'features/permit-to-work',
		features: [
			{
				name: 'Unlimited Permit to Work Applications',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Licenses',
		link: 'features/licenses',
		features: [
			{
				name: 'Unlimited Licenses',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Contracts',
		link: 'features/contracts',
		features: [
			{
				name: 'Unlimited Contracts',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Workflows',
		link: 'features/configurable-workflows',
		features: [
			{
				name: 'Automatic Assignment',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Automatic Email Alerts',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Attendance',
		link: 'features/attendance',
		features: [
			{
				name: 'Unlimited site locations',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Unlimited check in / check outs',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Responder Live Location',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
				link: 'features/responder-live-location',
			},
		],
	},
	{
		feature_group_name: 'Schedules',
		link: 'features/schedules',
		features: [
			{
				name: 'Unlimited Responder Scheduling',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Vendors',
		link: 'features/vendor-management',
		features: [
			{
				name: 'Unlimited Vendors and Invoices',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Schedule of Rates',
		link: 'features/schedule-of-rates',
		features: [
			{
				name: 'Unlimited Schedule of Rates',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Procurement',
		link: 'features/procurement',
		features: [
			{
				name: 'Unlimited RFQs',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Expenditures',
		link: 'features/expenditures',
		features: [
			{
				name: 'Unlimited Expenditures',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Budgets',
		link: 'features/budget',
		features: [
			{
				name: 'Unlimited Budgets',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Billing',
		link: 'features/billing',
		features: [
			{
				name: 'Unlimited Billing',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Payments',
		link: 'features/payments',
		features: [
			{
				name: 'Unlimited Payment tickets',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Customers',
		link: 'features/customers',
		features: [
			{
				name: 'Unlimited Customers',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Tenant portal',
		link: 'features/tenant-portal',
		features: [
			{
				name: 'Custom Subdomain and Configurable Sign In Page',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Facilities',
		link: 'features/facility-and-room-booking',
		features: [
			{
				name: 'Unlimited Facilities',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Unlimited Facility Bookings',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Room Tablet Webview',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Desks',
		link: 'features/hotdesks',
		features: [
			{
				name: 'Unlimited Zones / Hot Desks',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Deliveries',
		link: 'features/deliveries',
		features: [
			{
				name: 'Unlimited Deliveries',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Visitors',
		link: 'features/visitor-management',
		features: [
			{
				name: 'Unlimited Visitors',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Visitors Sign In Webview',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Visit Reception Tablet',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},

	{
		feature_group_name: 'FAQs',
		link: 'features/automated-faq-response',
		features: [
			{
				name: 'Unlimited FAQs',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Surveys',
		link: 'features/surveys',
		features: [
			{
				name: 'Unlimited Surveys',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Broadcasts',
		link: 'features/broadcasts',
		features: [
			{
				name: 'Unlimited Broadcasts',
				value: getAppliedPlans(ALL_PLANS),
			},
		],
	},
	{
		feature_group_name: 'Statistics',
		link: 'features/statistics',
		features: [
			{
				name: 'Custom Dashboard',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
				link: 'features/custom-dashboard',
			},
			{
				name: 'Map Dashboard',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
				link: 'features/map-dashboard',
			},
			{
				name: 'Pre-Configured Charts and Tables',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Export CSV',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Audit Log',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
		],
	},
	{
		feature_group_name: 'Integrations',
		features: [
			{
				name: 'Telegram',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Facebook',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Workplace',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Slack',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Teams',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Line',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Viber',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Webchat',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Google Chat',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'WhatsApp',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Sensors',
				value: getPlanValues({
					Professional: 'Up to 50',
					Plus: CUSTOM_CELL_VALUE.dynamicSensorsLimit,
				}),
				link: 'features/iot-sensors-integration',
			},
			{
				name: 'Building Information Model (BIM) Integration',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
				link: 'features/bim-integration',
			},
			{
				name: 'API',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Zapier',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Single Sign On',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Portfolio Portal',
				value: getAppliedPlans(ALL_PLANS),
				link: 'features/property-portfolio',
			},
		],
	},
	{
		feature_group_name: 'Support',
		features: [
			{
				name: 'In Platform Chat',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Zoom Support Call',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Email Support',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Dedicated Customer Success Manager',
				value: getAppliedPlans([PLANS.Professional, PLANS.Plus]),
			},
			{
				name: 'Standard Onboarding',
				value: getAppliedPlans(ALL_PLANS),
			},
			{
				name: 'Enhanced Onboarding',
				value: getPlanValues({
					Basic: CUSTOM_CELL_VALUE.enhancedOnboardingLink,
					Professional: CUSTOM_CELL_VALUE.enhancedOnboardingLink,
					Plus: CUSTOM_CELL_VALUE.enhancedOnboardingLink,
				}),
				link: 'resources/onboarding#enhanced-onboarding',
			},
		],
	},
	{
		feature_group_name: 'Storage',
		features: [
			{
				name: 'Maximum Storage',
				value: getPlanValues({
					Basic: '1TB',
					Professional: '2.5TB',
					Plus: CUSTOM_CELL_VALUE.dynamicStorage,
				}),
			},
		],
	},
];

export { PLANS, PLAN_BENEFITS };
