//* homepage
export { default as FacilityBotLogo } from './FacilityBotLogo';
export { default as FacilityBotNewyear2025Logo } from './FacilityBot2025Logo';
export { default as CheckboxFeature } from './CheckboxFeature';

//* social-icons
export { default as TwitterIcon } from './social-icons/twitter-icon';
export { default as FacebookIcon } from './social-icons/facebook-icon';
export { default as LinkedinIcon } from './social-icons/linkedin-icon';
export { default as YoutubeIcon } from './social-icons/youtube-icon';

//* resources-submenu
export { default as ApiDocumentation } from './resources-submenu/ApiDocumentation';
export { default as KnowledgeBase } from './resources-submenu/KnowledgeBase';
export { default as RoiCalculator } from './resources-submenu/RoiCalculator';
export { default as QuickSetup } from './resources-submenu/QuickSetup';

//* features-submenu
export { default as FaultReporting } from './features-submenu/FaultReporting';
export { default as PreventiveMaintenance } from './features-submenu/PreventiveMaintenance';
export { default as AssetManagement } from './features-submenu/AssetManagement';
export { default as QrCodeScanning } from './features-submenu/QrCodeScanning';
export { default as PhotoAndVideoEvidence } from './features-submenu/PhotoAndVideoEvidence';
export { default as Statistics } from './features-submenu/Statistics';
export { default as FacilityAndRoomBooking } from './features-submenu/FacilityAndRoomBooking';
export { default as Surveys } from './features-submenu/Surveys';
export { default as Broadcasts } from './features-submenu/Broadcasts';
export { default as AutomatedFaqResponse } from './features-submenu/AutomatedFaqResponse';
export { default as CustomServiceRequests } from './features-submenu/CustomServiceRequests';
export { default as Expenditures } from './features-submenu/Expenditures';
export { default as PropertyPortfolio } from './features-submenu/PropertyPortfolio';
export { default as VisitorManagement } from './features-submenu/VisitorManagement';
export { default as ConfigurableWorkflows } from './features-submenu/ConfigurableWorkflows';
export { default as Hotdesks } from './features-submenu/Hotdesks';
export { default as Licenses } from './features-submenu/Licenses';
export { default as QrCodeFaultReporting } from './features-submenu/QrCodeFaultReporting';
export { default as SensorMaps } from './features-submenu/SensorMaps';
export { default as TenantPortal } from './features-submenu/TenantPortal';
export { default as Payments } from './features-submenu/Payments';
export { default as Delivery } from './features-submenu/Delivery';

//* building-types-submenu
export { default as Commercial } from './building-types-submenu/Commercial';
export { default as Residential } from './building-types-submenu/Residential';
export { default as Education } from './building-types-submenu/Education';
export { default as Industrial } from './building-types-submenu/Industrial';
export { default as Government } from './building-types-submenu/Government';
export { default as Hotels } from './building-types-submenu/Hotels';
export { default as Retail } from './building-types-submenu/Retail';
export { default as SolutionCustomIcon } from './building-types-submenu/SolutionCustomIcon';

//* resource page
// Channel Partner
export { default as ResourceIcon1 } from './resource-page/resourcePage-1';
export { default as ResourceIcon2 } from './resource-page/resourcePage-2';
export { default as ResourceIcon3 } from './resource-page/resourcePage-3';
export { default as ResourceIcon4 } from './resource-page/resourcePage-4';

// Checklist Library
export { default as DownloadIcon } from './DownloadIcon';

export const FeatureIcon = (number) => {
	// Look in the features folder for the svg files
	const requireComponent = require.context('./feature-page', false, /\.js$/);

	// Get the component by its number
	const IconComponent = requireComponent(`./featurePage-${number}.js`).default;

	return <IconComponent />;
};
