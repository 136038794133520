import { useEffect } from 'react';
import { InputNumber, Tooltip } from 'antd';
import { CheckCircleTwoTone, InfoCircleOutlined, MinusCircleTwoTone } from '@ant-design/icons';

import { FacilityBotLogo } from '../Svg';
import { pricingPlansThumbnail } from '../../utils/images';
import { PLANS, PLAN_BENEFITS } from '../../data/pricing_plans';
import SignupForm from '../Form/signup';
import Link from 'next/link';

export default function PricingTable({ isAnnual, displayCurrency, plus }) {
	const billedBy = isAnnual ? 'annual' : 'monthly';
	const defaultAccounts = 50;
	const defaultPrice = 2220;

	const calcDynamicPlusPrice = (accounts) => {
		if (!isAnnual && accounts === defaultAccounts && displayCurrency === 'USD') {
			return defaultPrice;
		}

		const additionalAccounts = accounts - defaultAccounts;

		const perAccountPrice = {
			SGD: {
				monthly: 60,
				annual: 50,
			},
			USD: {
				monthly: 45,
				annual: 37,
			},
			INR: {
				monthly: 3600,
				annual: 3000,
			},
		};

		if (!isAnnual && displayCurrency === 'USD')
			return defaultPrice + additionalAccounts * perAccountPrice[displayCurrency][billedBy];

		return accounts * perAccountPrice[displayCurrency][billedBy];
	};

	const checkValChange = (val) => {
		if (val) {
			const price = calcDynamicPlusPrice(plus.accounts);
			plus.onPriceChange(price);
		}
	};

	useEffect(() => {
		checkValChange(displayCurrency);
		checkValChange(billedBy);
		checkValChange(plus.accounts);
	}, [displayCurrency, billedBy, plus.accounts]);

	const handlePlusAccounts = (val) => {
		if (val < 50 || isNaN(val)) {
			val = 50;
		}

		plus.onAccountsChange(val);

		const price = calcDynamicPlusPrice(val);

		plus.onPriceChange(price);
	};

	const isINR = () => {
		return displayCurrency === 'INR';
	};

	return (
		<div className="pricing-structure">
			<div className="plan-row">
				<PricingTableLogo />

				{Object.entries(PLANS).map(([key, { name, currency, maximum_accounts }]) => {
					const price = currency[displayCurrency][billedBy];

					return (
						<div key={key} className="plan-column plan-item">
							<div className="plan-item--name">{name}</div>

							<div className="plan-item--price">
								<span className={`price-number ${isINR() ? 'long-number' : ''}`}>
									{key === PLANS.Plus.name ? plus.price : price}
									<span className={`price-currency ${isINR() ? 'long-currency' : ''}`}>{displayCurrency}</span>
									<span className="price-interval">Month</span>
								</span>
							</div>

							<div className="plan-item--interval">Billed {isAnnual ? 'Yearly' : 'Monthly'}</div>

							{key === PLANS.Plus.name ? (
								<div className="plan-item--account-number">
									<Tooltip title="Paid accounts are needed for Admins, Managers and Responders (e.g. technicians, security or cleaning) to sign in to the FacilityBot web portal and mobile app. Requestors (i.e. tenants, building occupants or users who make fault reports and service requests) do not need to sign in and therefore do not require paid accounts">
										<span>Number of accounts </span>
										<InfoCircleOutlined className="" />
									</Tooltip>
									<InputNumber size="large" defaultValue={plus.accounts} onChange={handlePlusAccounts} min={50} />
								</div>
							) : (
								<div className="plan-item--account-number">
									<Tooltip title="Paid accounts are needed for Admins, Managers and Responders (e.g. technicians, security or cleaning) to sign in to the FacilityBot web portal and mobile app. Requestors (i.e. tenants, building occupants or users who make fault reports and service requests) do not need to sign in and therefore do not require paid accounts">
										<span>{maximum_accounts} Accounts </span>
										<InfoCircleOutlined className="" />
									</Tooltip>
								</div>
							)}

							<SignupForm btnClass="btn btn--cta-plan" btnText="get started" type={{ name: 'subscription' }} />
						</div>
					);
				})}
			</div>

			{PLAN_BENEFITS.map(({ feature_group_name, link, features }, planBenefitsIndex) => {
				return (
					<div key={planBenefitsIndex}>
						<div className="plan-row comparison-table feature-group">
							<div className="plan-column feature-group-name">
								{link ? (
									<Link href={link} rel="noopener noreferrer" target="_blank">
										{feature_group_name}
									</Link>
								) : (
									feature_group_name
								)}
							</div>
							<div className="plan-column"></div>
							<div className="plan-column"></div>
							<div className="plan-column"></div>
						</div>

						{features.map(({ name, value, link }, featureIndex) => {
							const borderBottomClass = featureIndex < features.length - 1 ? 'border-bottom' : '';

							return (
								<div className={`plan-row comparison-table ${borderBottomClass}`} key={featureIndex}>
									<div className="plan-column comparison-header">
										{link ? (
											<Link href={link} rel="noopener noreferrer" target="_blank">
												{name}
											</Link>
										) : (
											name
										)}
									</div>
									{value.map((item, valueIndex) => {
										const DisplayValueBuilder = (val) => {
											if (val === true) {
												return <CheckCircleTwoTone twoToneColor="#22D69B" />;
											} else if (val === false) {
												return <MinusCircleTwoTone twoToneColor="#FF6377" />;
											} else if (!isNaN(val)) {
												const isAccountsOfPlusPlan = planBenefitsIndex === 0 && featureIndex === 1 && valueIndex === 3;

												if (isAccountsOfPlusPlan) {
													return plus.accounts;
												}

												return val;
											} else if (typeof val === 'string') {
												const numberOfAccounts = plus.accounts;

												switch (val) {
													case 'custom_storage':
														return `${numberOfAccounts / 10} TB`;
													case 'custom_onboarding':
														return (
															<Link href="/resources/onboarding#enhanced-onboarding" className="blue-link">
																<i className="fas fa-dollar-sign"></i>
															</Link>
														);
													case 'limit_sensors':
														return `Up to ${numberOfAccounts * 2}`;
													default:
														return val;
												}
											}
										};

										return (
											<div className="plan-column" style={{ margin: 'auto' }} key={valueIndex}>
												{DisplayValueBuilder(item)}
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
}

const PricingTableLogo = () => (
	<div className="plan-column comparison-header">
		<FacilityBotLogo />
		<img
			src={pricingPlansThumbnail}
			alt=""
			style={{ marginTop: '30px', position: 'relative', left: '50%', transform: 'translate(-50%, 0%)' }}
		/>
	</div>
);
