import Head from 'next/head';
import { useContext } from 'react';
import { NonceContext } from '../../pages/_app';
import { icon, previewContentImage } from '../../utils/images';
import ExternalScripts from '../Script';

const FacebookDomainVerificationContent = '2sb71fmd0wyfbx3p6n7a242dvvfyhj';
const GoogleSiteVerificationContent = 'KY8uiOMux4F2jxeXUpcl3q7Yye3WsDel3NWyuuxXCAA';

const Meta = ({ title, desc, keyword }) => {
	const nonce = useContext(NonceContext);

	return (
		<>
			<Head>
				<title>{title}</title>

				<meta name="description" content={desc} />
				<meta name="keywords" content={keyword} />
				<meta name="version" content="v1.1.2" />

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="FacilityBot" />
				<meta property="og:url" content="https://facilitybot.co" />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={desc} />
				<meta property="og:image" content={`https://facilitybot.co${previewContentImage}`} />
				<meta property="og:image:secure_url" content={`https://facilitybot.co${previewContentImage}`} />
				<meta property="og:image:type" content="image/png" />
				<meta property="og:image:width" content="1202" />
				<meta property="og:image:height" content="629" />

				<meta name="facebook-domain-verification" content={FacebookDomainVerificationContent} />

				<meta name="google-site-verification" content={GoogleSiteVerificationContent} />

				{/* Twitter */}
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content="https://facilitybot.co" />
				<meta property="twitter:title" content={title} />
				<meta property="twitter:description" content={desc} />
				<meta property="twitter:image" content={`https://facilitybot.co${previewContentImage}`} />
				<meta property="twitter:site" content="@FacilityBot" />
				<meta property="twitter:creator" content="@FacilityBot" />

				<meta name="viewport" content="width=device-width, initial-scale=1.0" />

				<link rel="icon" type="image/png" href={icon} />
			</Head>

			<ExternalScripts nonce={nonce} />
		</>
	);
};

export default Meta;
