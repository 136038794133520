export const convertArray = (originalArray) => {
	return originalArray.map((item, index) => {
		const convertedItem = {
			side_note: index + 1,
			side_node: index + 1,
			description: item,
			location: '',
			meta_data: {
				blocks: [
					{
						type: 'radio',
						compulsory: false,
						title: 'Single choice response',
						content: [
							{
								title: 'Yes',
								value: false,
							},
							{
								title: 'No',
								value: false,
							},
							{
								title: 'N/A',
								value: false,
							},
						],
					},
				],
			},
		};
		return convertedItem;
	});
};

export const objectToFormData = (obj) => {
	const formData = new FormData();

	for (const key in obj) {
		if (typeof obj[key] === 'object' && obj[key] !== null) {
			// If the property is an object, convert it to a JSON string
			formData.append(key, JSON.stringify(obj[key]));
		} else {
			formData.append(key, obj[key]);
		}
	}
	return formData;
};

export const payloadToCreateChecklist = (titleData, descriptionData, formattedTime, futureDate, rowsPayload) => {
	return {
		checklist_name: titleData,
		checklist_notes: descriptionData,
		checklist_is_draft: true,
		checklist_staff_ids: [],
		checklist_manager_ids: [],
		checklist_require_photo: false,
		checklist_require_completion_remarks: true,
		checklist_require_check_time: false,
		checklist_asset_tracking_ids: [],
		checklist_label_ids: [],
		checklist_is_public: false,
		recurrence_start_datetime: formattedTime,
		recurrence_kind: 'day',
		recurrence_number_repeat: 1,
		recurrence_day_of_week: [1],
		recurrence_day_of_month: 0,
		recurrence_week_of_month: 0,
		recurrence_day_in_week: 0,
		recurrence_never_stop: false,
		recurrence_enabled_end_on: false,
		recurrence_end_on: futureDate,
		recurrence_enabled_number_limit: true,
		recurrence_number_limit: 0,
		recurrence_next_datetime: [],
		data_checklist_rows: JSON.stringify(rowsPayload),
		checklist_location_tag_ids: [],
	};
};

export const payloadToExportPdf = (title, description, checklist) => {
	const payload = {
		layout_setting: {
			id: '',
			show_logo: true,
			name: '2',
			layout: 'portrait',
			show_title: true,
			show_id: true,
			show_note: true,
			show_checked_by_column: true,
			show_signed_off_by_column: true,
			number_of_image_per_row: 1,
			show_timestamp_on_image: true,
			show_geolocation_of_image: true,
			show_description: true,
			show_sn: true,
			is_default: true,
			font_size_title: 16,
			font_size_body: 10,
			vertical_padding: 0,
			is_website: true,
		},
		header: {
			title: title,
			checklist_id: new Date(),
			note: description,
			enable_score: false,
			score: null,
		},
		logo_url: 'https://portal.uat.facilitybot.co/images/logo.png',
		body: {
			checklist_info: {
				checked_by: '',
				signed_off_by: '',
				remarks_check: '',
				remarks_sign_off: '',
				check_time: {
					checked_at: '',
				},
				signed_off_time: '',
			},
			table: {
				headers: [
					{
						label: 'S/N',
						property: 'status',
						width: 5,
					},
					{
						label: 'Description',
						property: 'processed_by',
						width: 200,
					},
					{
						label: 'Checks',
						property: 'processed_at',
						width: 200,
					},
				],
				datas: [],
			},
		},
	};

	checklist.forEach((item, index) => {
		const dataItem = {
			serial_number: index + 1,
			description: item,
			checks: [
				{
					title: '',
					content: [
						{
							title: 'Yes',
							value: false,
						},
						{
							title: 'No',
							value: false,
						},
						{
							title: 'N/A',
							value: false,
						},
					],
					type: 'radio',
				},
			],
		};

		payload.body.table.datas.push(dataItem);
	});
	return payload;
};

export const frequencyOptions = [
	{
		value: 'daily',
		label: 'Daily',
	},
	{
		value: 'weekly',
		label: 'Weekly',
	},
	{
		value: 'monthly',
		label: 'Monthly',
	},
	{
		value: 'quarterly',
		label: 'Quarterly',
	},
	{
		value: 'annual',
		label: 'Annual',
	},
];

import axios from 'axios';
export const downloadFile = (data) => {
	axios
		.get(data.url, { responseType: 'blob' })
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.name);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
		.catch((error) => console.error('Error:', error));
};
