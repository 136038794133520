import { useState } from 'react';
import { CALENDLY_LINK } from '../../constants';
import { openNotification } from '../../utils';
import mailjetService, { interestedLeadsListId } from '../../services/mailjetService';
import senderService from '../../services/senderService';
import { SENDER_GROUPS } from '../../constants/sender';

export default function MailForm() {
	const [email, setEmail] = useState('');

	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			gtag_report_conversion(CALENDLY_LINK);

			const inputField = e.target.children[0];
			if (window.getComputedStyle(inputField).display === 'none') {
				window.location.assign(CALENDLY_LINK);
			}

			if (!email.trim().length) {
				openNotification('error', 'Error', 'Please enter your email');
				return;
			}

			Calendly.initPopupWidget({
				url: CALENDLY_LINK,
				prefill: {
					email: email,
				},
			});

			await mailjetService.post(`/contactslist/${interestedLeadsListId}/managecontact`, {
				Action: 'addnoforce',
				Email: email,
			});

			await senderService.post('/subscribers', {
				email: email,
				groups: [SENDER_GROUPS.DEMO_SIGN_UP],
			});
		} catch (err) {
			console.error('🚀 ~ file: mail.js ~ line 40 ~ handleSubmit ~ err', err);
		}
	};

	return (
		<form className="mail__form" onSubmit={handleSubmit} noValidate>
			<input type="email" placeholder="Email" className="mail__form-inp" onChange={(e) => setEmail(e.target.value)} />
			<button type="submit" className="mail__form-btn">
				schedule demo
			</button>
		</form>
	);
}
